import React from "react";
import Muinavbar from "./Muinavbar";
import { Box, Button, Divider, Typography } from "@mui/material";
import Slider from "./Slider";
import axios from "axios";
import { useNavigate } from "react-router";

const mycomponent = {
  height: "370px",
  overflowY: "scroll",
};

export default function Home({ cartDataLength }) {
  const navigate = useNavigate();
  const [Data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const getData = async () => {
    setLoader(true);
    try {
      let res = await axios.get("https://ecombackend.glitch.me/getallproduct");
      if (res.data.data) {
        const { data } = res.data;
        setData(data);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  if (loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
          background: "#1f0060",
        }}
      >
        <img
          src={
            "https://cdn.svgator.com/images/2023/06/fingerprint-scan-css-loader.svg"
          }
          alt="loader"
          width="80%"
        />
      </div>
    );
  }
  return (
    <>
      <Muinavbar cartDataLength={cartDataLength} />
      <center>
        <Box
          sx={{
            height: "auto",
            width: "95%",
            display: "flex",
            flexDirection: ["column-reverse", "row"],
            mt: 10,
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: ["100%", "50%", "30%"],
              height: "400px",
              borderRadius: 3,
              pt: 1,
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <Typography variant="h5">Top Deals</Typography>
            <Divider />
            <Box sx={mycomponent}>
              {Data?.length !== 0 ? (
                Data?.slice(0, 4).map((item) => {
                  return (
                    <Box
                      key={item._id}
                      sx={{
                        height: "fit-content",
                        width: ["100%", "90%"],
                        mt: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/viewproduct/${item._id}`)}
                    >
                      <img
                        src={item.ProductMainImgUrl}
                        width="60px"
                        height="70px"
                        alt={item.ProductName}
                      />
                      <Box
                        sx={{
                          width: "70%",
                          height: "fit-content",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          justifyItems: "left",
                          textAlign: "left",
                        }}
                      >
                        <Typography variant="body1">
                          {item.ProductName.slice(0, 35) + "..."}
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "left" }}>
                          {item.ProductShortDesc.slice(0, 35) + "..."}
                        </Typography>
                        <Typography variant="body2">
                          ₹{" "}
                          {item.ProductPrice > 9999
                            ? item.ProductPrice.toString().slice(0, 2) +
                              "," +
                              item.ProductPrice.toString().slice(2)
                            : item.ProductPrice > 999
                            ? item.ProductPrice.toString().slice(0, 1) +
                              "," +
                              item.ProductPrice.toString().slice(1)
                            : item.ProductPrice}
                          <span
                            style={{
                              textDecoration: "line-through",
                              marginLeft: "8px",
                            }}
                          >
                            ₹
                            {item.ProductMRP > 9999
                              ? item.ProductMRP.toString().slice(0, 2) +
                                "," +
                                item.ProductMRP.toString().slice(2)
                              : item.ProductMRP > 999
                              ? item.ProductMRP.toString().slice(0, 1) +
                                "," +
                                item.ProductMRP.toString().slice(1)
                              : item.ProductMRP}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h2 style={{ textAlign: "center" }}>No Product Found</h2>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              boxSizing: "border-box",
              width: ["95%", "50%", "70%"],
              height: "400px",
              backgroundColor: "white",
              borderRadius: 4,
            }}
          >
            <Slider />
          </Box>
        </Box>
        <Box
          sx={{
            width: "95%",
            height: "auto",
            backgroundColor: "white",
            borderRadius: 2,
            pt: 2,
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "left",
              width: "95%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Flash Deals
            <Button
              size="small"
              color="secondary"
              onClick={() => navigate("/Filter/All")}
            >
              See More
            </Button>
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left", width: "93%" }}
          >
            Upto 60% Off
          </Typography>
          <Divider />
          <Box
            sx={{
              width: "100%",
              height: "70%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              mt: 2,
            }}
          >
            {Data?.length !== 0 ? (
              Data?.slice(4, 8).map((item) => {
                return (
                  <div className="card-flash" key={item._id}>
                    <img
                      src={item.ProductMainImgUrl}
                      alt={item.ProductName}
                      style={{ width: 100, height: 100 }}
                    />
                    <h1
                      style={{
                        fontSize: "1rem",
                        height: 40,
                        overflow: "hidden",
                      }}
                    >
                      {item.ProductName.slice(0, 50) + "..."}
                    </h1>
                    <p className="price">
                      ₹{" "}
                      {item.ProductPrice > 9999
                        ? item.ProductPrice.toString().slice(0, 2) +
                          "," +
                          item.ProductPrice.toString().slice(2)
                        : item.ProductPrice > 999
                        ? item.ProductPrice.toString().slice(0, 1) +
                          "," +
                          item.ProductPrice.toString().slice(1)
                        : item.ProductPrice}
                    </p>
                    <p style={{ height: 55, overflow: "hidden" }}>
                      {item.ProductShortDesc}
                    </p>
                    <p>
                      <button
                        onClick={() => navigate(`/viewproduct/${item._id}`)}
                      >
                        View Product
                      </button>
                    </p>
                  </div>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h2 style={{ textAlign: "center" }}>No Product Found</h2>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "95%",
            height: "auto",
            borderRadius: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: ["100%", "49%"],
              height: "auto",
              backgroundColor: "white",
              borderRadius: 2,
              pt: 2,
              mb: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: "90%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Premium Dry Fruits
              <Button
                size="small"
                color="secondary"
                onClick={() => navigate("/filter/Dry Fruits Premium")}
              >
                See More
              </Button>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "left", width: "85%" }}
            >
              Best Quality
            </Typography>
            <Divider />
            <Box
              sx={{
                width: "100%",
                height: "70%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              {Data?.length !== 0 ? (
                Data?.filter((product) => {
                  return product.ProductCategory === "Dry Fruits Premium";
                })
                  .slice(0, 2)
                  .map((item) => {
                    return (
                      <div className="card-flash" key={item._id}>
                        <img
                          src={item.ProductMainImgUrl}
                          alt={item.ProductName}
                          style={{ width: 100, height: 100 }}
                        />
                        <h1
                          style={{
                            fontSize: "1rem",
                            height: 40,
                            overflow: "hidden",
                          }}
                        >
                          {item.ProductName.slice(0, 50) + "..."}
                        </h1>
                        <p className="price">
                          ₹{" "}
                          {item.ProductPrice > 9999
                            ? item.ProductPrice.toString().slice(0, 2) +
                              "," +
                              item.ProductPrice.toString().slice(2)
                            : item.ProductPrice > 999
                            ? item.ProductPrice.toString().slice(0, 1) +
                              "," +
                              item.ProductPrice.toString().slice(1)
                            : item.ProductPrice}
                        </p>
                        <p style={{ height: 55, overflow: "hidden" }}>
                          {item.ProductShortDesc}
                        </p>
                        <p>
                          <button
                            onClick={() => navigate(`/viewproduct/${item._id}`)}
                          >
                            View Product
                          </button>
                        </p>
                      </div>
                    );
                  })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h2 style={{ textAlign: "center" }}>No Product Found</h2>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: ["100%", "49%"],
              height: "auto",
              backgroundColor: "white",
              borderRadius: 2,
              pt: 2,
              mb: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: "90%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Bulk Dry Fruits
              <Button
                size="small"
                color="secondary"
                onClick={() => navigate("/filter/Dry Fruits Bulk")}
              >
                See More
              </Button>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "left", width: "85%" }}
            >
              Bulk Orders
            </Typography>
            <Divider />
            <Box
              sx={{
                width: "100%",
                height: "70%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              {Data?.length !== 0 ? (
                Data?.filter((product) => {
                  return product.ProductCategory === "Dry Fruits Bulk";
                })
                  .slice(0, 2)
                  .map((item) => {
                    return (
                      <div className="card-flash" key={item._id}>
                        <img
                          src={item.ProductMainImgUrl}
                          alt={item.ProductName}
                          style={{ width: 100, height: 100 }}
                        />
                        <h1
                          style={{
                            fontSize: "1rem",
                            height: 40,
                            overflow: "hidden",
                          }}
                        >
                          {item.ProductName.slice(0, 50) + "..."}
                        </h1>
                        <p className="price">
                          ₹{" "}
                          {item.ProductPrice > 9999
                            ? item.ProductPrice.toString().slice(0, 2) +
                              "," +
                              item.ProductPrice.toString().slice(2)
                            : item.ProductPrice > 999
                            ? item.ProductPrice.toString().slice(0, 1) +
                              "," +
                              item.ProductPrice.toString().slice(1)
                            : item.ProductPrice}
                        </p>
                        <p style={{ height: 55, overflow: "hidden" }}>
                          {item.ProductShortDesc}
                        </p>
                        <p>
                          <button
                            onClick={() => navigate(`/viewproduct/${item._id}`)}
                          >
                            View Product
                          </button>
                        </p>
                      </div>
                    );
                  })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h2 style={{ textAlign: "center" }}>No Product Found</h2>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </center>
    </>
  );
}
