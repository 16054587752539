import React, { useEffect, useState } from "react";
import Header from "./Header";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import ReactLoading from 'react-loading';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function AddProduct() {
  const [product, addproduct] = useState({
    ProductName: "",
    ProductMRP: "",
    ProductPrice: "",
    ProductMainImgUrl: "",
    ProductShortDesc: "",
    ProductLongDesc: "",
    ProductColor: "",
    ProductCategory: "",
    ProductBrand: "",
    ProductSize: "",
    ProductQuantity: "",
    ProductDiscount: "",
    ProductGST: "",
    ProductHSN:""
  });
  const [loading, setloading] = useState(false);
  const [productCategories, setproductCategories] = useState([]);
  const [CategoryModel, setCategoryModel] = useState(false);
  const [CategoryName, setCategoryName] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    addproduct({ ...product, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    addproduct({ ...product, ProductMainImgUrl: file });
  };

  const getCategory = async () => {
    await axios
      .get("https://ecombackend.glitch.me/GetCategory")
      .then((res) => {
        const { data } = res.data;
        setproductCategories(data);
      });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleAddCategory = async () => {
    setloading(true);
    try {
      if(CategoryName === ''){
        toast.error("Select or Create Category")
        setloading(false)
        return
      }
      let res = await axios.post(
        "https://ecombackend.glitch.me/AddCategory",
        { CategoryName }
      );

      if (res.data) {
        setloading(false);
        toast.success(res.data.message);
        setCategoryModel(false);
      }
    } catch (err) {
      toast.error("Try again");
      setloading(false);
      setCategoryModel(false);
    }
  }
  
  const validateForm = ()=>{
    if(!product.ProductPrice){
      toast.error('Enter Product Price')
      return true;
    }
    if(!product.ProductName){
      toast.error('Enter Product Name')
      return true;
    }    
    if(!product.ProductMRP){
      toast.error('Enter Product Name')
      return true;
    }
    if(!product.ProductCategory && product.ProductCategory === ""){
      toast.error('Select Product Category')
      return true;
    }    

    return false;
  }

  const handleSubmit = async () => {
    setloading(true);
    if(validateForm()){
      setloading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("ProductName", product.ProductName);
      formData.append("ProductMRP", product.ProductMRP);
      formData.append("ProductPrice", product.ProductPrice);
      formData.append("ProductMainImgUrl", product.ProductMainImgUrl);
      formData.append("ProductShortDesc", product.ProductShortDesc);
      formData.append("ProductLongDesc", product.ProductLongDesc);
      formData.append("ProductColor", product.ProductColor);
      formData.append("ProductCategory", product.ProductCategory);
      formData.append("ProductBrand", product.ProductBrand);
      formData.append("ProductSize", product.ProductSize);
      formData.append("ProductQuantity", product.ProductQuantity);
      formData.append("ProductDiscount", product.ProductDiscount);
      formData.append("ProductGST", product.ProductGST);
      formData.append("ProductHSN", product.ProductHSN);

      let res = await axios.post(
        "https://ecombackend.glitch.me/AddProduct",
        formData
      );

      if (res.data) {
        setloading(false);
        toast.success(res.data.message);
        addproduct({
          ProductName: "",
          ProductMRP: "",
          ProductPrice: "",
          ProductMainImgUrl: "",
          ProductShortDesc: "",
          ProductLongDesc: "",
          ProductColor: "",
          ProductCategory: "",
          ProductBrand: "",
          ProductSize: "",
          ProductQuantity: "",
          ProductDiscount: "",
          ProductGST: "",
          ProductHSN:""
        });
      }
    } catch (err) {
      toast.error("Try again");
      setloading(false);
    }
  };
  return (
    <>
      <Header />
      <div className="form-field">
        <Typography
          variant="h5"
          sx={{ width: "100%", mb: 4, display: "flex", alignItems: "center" }}
        >
          <AddCircleOutlineTwoToneIcon
            sx={{ color: "#6945ff", mr: 1 }}
          ></AddCircleOutlineTwoToneIcon>
          Add a Product:
        </Typography>
        {loading ? <center><ReactLoading type='spin' color='black' height={200} width={100} /></center> :
          <form>
            <div className="input-field">
              <label className="label-text">Product Name:</label>
              <br />
              <input
                type="text"
                value={product.ProductName}
                name="ProductName"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product MRP:</label>
              <br />
              <input
                type="tel"
                value={product.ProductMRP}
                name="ProductMRP"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Price:</label>
              <br />
              <input
                type="tel"
                value={product.ProductPrice}
                name="ProductPrice"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Short Description:</label>
              <br />
              <input
                type="text"
                value={product.ProductShortDesc}
                name="ProductShortDesc"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Long Description:</label>
              <br />
              <input
                type="text"
                value={product.ProductLongDesc}
                name="ProductLongDesc"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Main Image URL:</label>
              <br />
              <input
                type="file"
                accept="image/*"
                name="ProductMainImgUrl"
                onChange={handleFileChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', padding: 0, margin: 0, height: 'auto' }}>
                <label className="label-text">Product Category:</label>
                <label className="label-right" onClick={() => setCategoryModel(true)} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Create Category</label>
              </div>
              <Select
                value={product.ProductCategory}
                name="ProductCategory"
                onChange={handleChange}
                style={{ width: '80%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productCategories.map((category) => (
                  <MenuItem key={category._id} value={category.CategoryName}>
                    {category.CategoryName}
                  </MenuItem>
                ))}
              </Select>

            </div>

            <div className="input-field">
              <label className="label-text">Product Brand:</label>
              <br />
              <input
                type="text"
                value={product.ProductBrand}
                name="ProductBrand"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Color:</label>
              <br />
              <input
                type="text"
                value={product.ProductColor}
                name="ProductColor"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Size:</label>
              <br />
              <input
                type="text"
                value={product.ProductSize}
                name="ProductSize"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Quantity:</label>
              <br />
              <input
                type="text"
                value={product.ProductQuantity}
                name="ProductQuantity"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>            
            <div className="input-field">
              <label className="label-text">Product Discount:</label>
              <br />
              <input
                type="text"
                value={product.ProductDiscount}
                name="ProductDiscount"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>            
            <div className="input-field">
              <label className="label-text">Product GST:</label>
              <br />
              <input
                type="text"
                value={product.ProductGST}
                name="ProductGST"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product HSN:</label>
              <br />
              <input
                type="text"
                value={product.ProductHSN}
                name="ProductHSN"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <input
                type="button"
                value="Add Product"
                onClick={handleSubmit}
                className="register-button"
              />
            </div>
          </form>
        }
        <Dialog
          open={CategoryModel}
          onClose={() => setCategoryModel(false)}
        >
          <Box sx={{ width: ["auto", 380], height: ["auto", 250] }}>
            <DialogTitle
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Create Category
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setCategoryModel(false)}
              >
                X
              </span>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1.5, width: "90%" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: ["auto"],
                }}
              >
                <TextField
                  type="text"
                  name="CategoryName"
                  value={CategoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  variant="standard"
                  label="New Category Name"
                  fullWidth
                />
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleAddCategory}
                  color="primary"
                  sx={{ width: "90%", height: 40 }}
                >
                  Create Category
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>

        <ToastContainer />
      </div>
    </>
  );
}
