import React from "react";
import { useNavigate } from "react-router";
import SimpleImageSlider from "react-simple-image-slider";

const Slider = () => {
  const navigate = useNavigate();

  const sliderImages = [
    {
      url:
        "https://images.pexels.com/photos/86649/pexels-photo-86649.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1",
    },
    {
      url:
        "https://images.pexels.com/photos/625422/pexels-photo-625422.jpeg?auto=compress&cs=tinysrgb&w=1170",
    },
    {
      url:
        "https://images.pexels.com/photos/1295572/pexels-photo-1295572.jpeg?auto=compress&cs=tinysrgb&fit=crop&w=1170&q=80",
    },
    {
      url:
        "https://images.pexels.com/photos/3872425/pexels-photo-3872425.jpeg?auto=compress&cs=tinysrgb&w=1170&h=750&dpr=1",
    },
  ];
  const getWidth = () => {
    const windowWidth = window.innerWidth;
    return windowWidth >= 765 ? "50%" : "90%";
  };

  const getMargin = () => {
    const windowWidth = window.innerWidth;
    return windowWidth >= 765 ? -165 : -30;
  };

  return (
      <SimpleImageSlider
        width={getWidth()}
        height={400}
        images={sliderImages}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        onClick={() => navigate("/Filter/All")}
        autoPlayDelay={5}
        style={{ cursor: "pointer", borderRadius: 4,marginLeft:getMargin() }}
      />
  );
};

export default Slider;
