import React from "react";
import {
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  List,
  Drawer,
  CssBaseline,
  Toolbar,
  Typography,
  AppBar,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//icons
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CategoryIcon from '@mui/icons-material/Category';
import DvrIcon from '@mui/icons-material/Dvr';
export default function Header() {
  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem("login");
    navigate("/admin/");
  };
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "#6945FF" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" noWrap component="div" sx={{width:["50%","100%"],fontSize:{xs:'1rem',sm:"1rem"}}}>
            Admin Panal
          </Typography>
          {isSmallerScreen ?
          <Typography component="div" sx={{cursor:'pointer',width:"100%"}}>
          <Typography variant="button" sx={{ml:[0,2]}} onClick={()=>navigate('/Products')}> Products </Typography>
          <Typography variant="button" sx={{ml:[1,2]}} onClick={()=>navigate('/orders')}> Orders </Typography>
          <Typography variant="button" sx={{ml:[1,2]}} onClick={()=>navigate('/Category')}> Category </Typography>
          <Typography variant="button" sx={{ml:[1,2]}} onClick={Logout}> Logout </Typography>
          </Typography>
: <></>}
        </Toolbar>
      </AppBar>
      <Sidebar />
    </>
  );
}

// ***SideBar***

function Sidebar() {
  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem("login");
    navigate("/");
  };
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 300,
            boxSizing: "border-box",
            display: isSmallerScreen ? "none" : "block",
          }        
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            <ListItem sx={{ height: 130, flexDirection: "column" }}>
              <ListItemIcon>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                  height="64px"
                  width="60px"
                  alt="profileImg"
                />
                <br></br>
              </ListItemIcon>
              <Typography sx={{ fontSize: "14px", color: "#6C7177" }}>
                Welcome, Admin
              </Typography>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/admin/Products")}>
                <ListItemIcon>
                  <ApartmentIcon sx={{ color: "#6945FF" }} />
                </ListItemIcon>
                <ListItemText sx={{ fontSize: "14px" }} primary="Products" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/admin/Orders")}>
                <ListItemIcon>
                  <DvrIcon sx={{ color: "#6945FF" }} />
                </ListItemIcon>
                <ListItemText sx={{ fontSize: "14px" }} primary="Orders" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/admin/Category")}>
                <ListItemIcon>
                  <CategoryIcon sx={{ color: "#6945FF" }} />
                </ListItemIcon>
                <ListItemText sx={{ fontSize: "14px" }} primary="Category" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={Logout}>
                <ListItemIcon>
                  <ApartmentIcon sx={{ color: "#6945FF" }} />
                </ListItemIcon>
                <ListItemText sx={{ fontSize: "14px" }} primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}
