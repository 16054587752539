import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Divider,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import Muinavbar from "./Muinavbar";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
export default function Payment({ setCartDataLength, cartDataLength }) {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [cdl, setcdl] = useState(0);
  const [itemlen, setitemlen] = useState(true);
  const [Address,setAddress] = useState([])
  let ta = 0;
  Data?.map((item) => {
    ta = ta + item?.ProductPrice;
    return item;
  });
  let discount = 0;
  discount = ta - (ta * 0) / 100;

  const getData = async () => {
    const cartData = JSON.parse(localStorage.getItem("cartdata"));
    if (cartData?.length > 0) {
      setitemlen(false);
      }    
      setData(cartData);
      if(cartData){
      try{
        const UserId = localStorage.getItem("UserId");
        let res = await axios.get(`https://ecombackend.glitch.me/getAddress/${UserId}`)
        setAddress(res.data.data[0]?.Address + ', ' + res.data.data[0]?.City + ', ' + res.data.data[0]?.State)
      }catch(err){
        console.log(err)
      }
    }
  };
  
  useEffect(() => {
    getData();

    const cartData = JSON.parse(localStorage.getItem("cartdata"));
    const cartDataLengt = cartData ? cartData?.length : 0;
    setcdl(cartDataLengt);
  }, []);
  const handleClick = async (data) => {
    try {
      let res = await axios.post(
        "https://ecombackend.glitch.me/Order/Placed",
        data
      );
      if (res) {
        toast.info(res, { autoClose: 5000 });
        localStorage.removeItem("cartdata");
        setCartDataLength(0);
        navigate("/");
      }
    } catch (err) {
      toast.error("Order Not Placed", { autoClose: 5000 });
    }
  };
  return (
    <div className="address-field">
      <Muinavbar cartDataLength={cartDataLength} />
      <Box sx={{ display: "flex", flexDirection: ["column","row"], mt: 10 }}>
        <Box sx={{ mt: 5, ml: [2,3], mb: 5, height: "fit-content", width: ["85%","63%"] }}>
          <Paper
            elevation={3}
            component="form"
            sx={{
              bgcolor: "#fff",
              p: 3,
              width: "90%",
              height: "fit-content",
              border: "2px solid black",
              borderRadius: 3,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Your Address
            </Typography>
            <Divider></Divider>
            <FormControl sx={{ mt: 2 }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="address 1"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="address 1"
                  control={<Radio />}
                  label={Address}
                />
              </RadioGroup>
            </FormControl>
            <br />
          </Paper>
          <Paper
            elevation={3}
            component="form"
            sx={{
              bgcolor: "#fff",
              p: 3,
              width: "90%",
              height: "fit-content",
              mt: 5,
              border: "2px solid black",
              borderRadius: 3,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Payment
            </Typography>
            <Divider></Divider>
            <Typography sx={{ mt: 2, fontWeight: "bold" }}>
              Payment Options:
            </Typography>
            <FormControl sx={{ mt: 2 }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="card"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Paytm"
                  control={<Radio />}
                  label="Paytm"
                />
                <FormControlLabel
                  value="Cod"
                  control={<Radio />}
                  label="Cash on Delivery"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Box>
        <Box sx={{ height: "fit-content", width: 300, mt: 5, ml: [2,3],mb:[2,0] }}>
          <Paper
            elevation={3}
            component="form"
            sx={{
              bgcolor: "#fff",
              p: 3,
              width: "90%",
              height: "fit-content",
              border: "2px solid black",
              borderRadius: 3,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Order Summary
            </Typography>
            <Divider></Divider>
            <Box sx={{ display: "flex", alignItems: "row", mt: 2 }}>
              <Box sx={{ width: 500 }}>
                <Typography sx={{ mb: 1 }}>Total Items:</Typography>
                <Typography sx={{ mb: 1 }}>Total Price:</Typography>
                <Typography sx={{ mb: 1 }}>Delivery Charges:</Typography>
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography sx={{ mb: 1 }}>
                  {cartDataLength !== 0 ? cartDataLength : cdl}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  {" "}
                  ₹{" "}
                  {ta > 9999
                    ? ta.toString().slice(0, 2) + "," + ta.toString().slice(2)
                    : ta > 999
                    ? ta.toString().slice(0, 1) + "," + ta.toString().slice(1)
                    : ta}
                </Typography>
                <Typography sx={{ mb: 1 }}>₹ {"00"}</Typography>
              </Box>
            </Box>
            <Divider sx={{ mt: 1 }}></Divider>
            <Box sx={{ display: "flex", alignItems: "row", mt: 2 }}>
              <Box sx={{ width: 500 }}>
                <Typography sx={{ mb: 1 }}>Order Total:</Typography>
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography sx={{ mb: 1 }}>
                  ₹
                  {discount.toFixed(0) > 9999
                    ? discount.toFixed(0).toString().slice(0, 2) +
                      "," +
                      discount.toFixed(0).toString().slice(2)
                    : discount.toFixed(0) > 999
                    ? discount.toFixed(0).toString().slice(0, 1) +
                      "," +
                      discount.toFixed(0).toString().slice(1)
                    : discount.toFixed(0)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "row", mt: 2 }}>
              <Box sx={{ width: 500 }}>
                <Button
                  disabled={itemlen}
                  variant="contained"
                  color="success"
                  onClick={() => handleClick(Data)}
                >
                  Place Order
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
}
