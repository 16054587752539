import React, { useState } from "react";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cart from "./components/Cart";
import Profile from "./components/Profile";
import Filter from "./components/Filter";
import Viewproduct from "./components/Viewproduct";
import Payment from "./components/Payment";
import "./App.css";
import Order from "./components/Order";

// Admins
import Login from "./Admin/Login";
import Header from "./Admin/Header";
import Products from "./Admin/Products";
import AddProduct from "./Admin/AddProduct";
import EditProduct from "./Admin/EditProduct";
import Category from "./Admin/Category";
import Orders from "./Admin/Orders";

function App() {
  const [cartDataLength, setCartDataLength] = useState(0);
  const [ProductData,setProductData] = useState(null);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home cartDataLength={cartDataLength} />} />
        <Route
          path="/cart"
          element={
            <Cart
              setCartDataLength={setCartDataLength}
              cartDataLength={cartDataLength}
            />
          }
        />
        <Route
          path="/profile"
          element={<Profile cartDataLength={cartDataLength} />}
        />
        <Route
          path="/filter/:id"
          element={<Filter cartDataLength={cartDataLength} />}
        />
        <Route
          path="/viewproduct/:id"
          element={
            <Viewproduct
              setCartDataLength={setCartDataLength}
              cartDataLength={cartDataLength}
            />
          }
        />
        <Route
          path="/payment"
          element={
            <Payment
              setCartDataLength={setCartDataLength}
              cartDataLength={cartDataLength}
            />
          }
        />
        <Route
          path="/orders"
          element={
            <Order
              cartDataLength={cartDataLength}
            />
          }
        />


        {/* Admin Panel */}
          <Route exact path="/admin" element={<Login/>} />
          <Route exact path="/admin/Header" element={<Header />} />
          <Route exact path="/admin/Products" element={<Products setProductData={setProductData} />} />
          <Route exact path="/admin/AddProduct" element={<AddProduct />} />
          <Route exact path="/admin/EditProduct" element={<EditProduct ProductData={ProductData} setProductData={setProductData}/>} />
          <Route exact path="/admin/Orders" element={<Orders/>} />
          <Route exact path="/admin/Category" element={<Category/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;