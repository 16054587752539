import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import Muinavbar from "./Muinavbar";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Order = ({ cartDataLength }) => {
  const [orders, setOrders] = useState([]);
  const userid = localStorage.getItem('UserId');
  useEffect(() => {
  const fetchOrders = async () => {
    try {
      const response = await axios.get(`https://ecombackend.glitch.me/Orders/${userid}`);
      if(response.data.data.length > 0){
        setOrders(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

    fetchOrders();
  }, [userid]);
  const handleCancel = async(id)=>{
    try{
      let response = await axios.post('https://ecombackend.glitch.me/OrderUpdateStatus',{id,Statusmsg:"Cancelled"});
      if(response){
        toast.success("Order Cancelled")
      }
    }
    catch(err){
      console.log(err);
    }
  }
  return (
    <>
    <Muinavbar cartDataLength={cartDataLength} />
    <center>
        <Box
          sx={{
            width: "96%",
            mt: 13,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            My Orders
          </Typography>
          <Divider sx={{ width: "100%", mb: 3 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {orders.length > 0 ? (
              orders.map((order) => (
                <Box
                  key={order._id}
                  sx={{
                    width: "90%",
                    mb: 3,
                    p: 2,
                    border: "1px solid grey",
                    borderRadius: 2,
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: ["column", "row"],
                    alignItems: ["center", "flex-start"],
                  }}
                >
                  <Box
                    sx={{
                      width: ["100%", "30%"],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: [2, 0],
                    }}
                  >
                    <img
                      src={order.ProductMainImgUrl}
                      alt={order.ProductName}
                      style={{ width: "100%", maxWidth: "150px", height: "auto", borderRadius: 4 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: ["100%", "70%"],
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      pl: [0, 2],
                      textAlign: ["center", "left"]
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1 }}>{order.ProductName}</Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      <strong>Price:</strong> ₹{order.ProductPrice.toLocaleString("en-IN")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      <strong>Quantity:</strong> {order.ProductQuantity}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      <strong>Size:</strong> {order.ProductSize}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      <strong>Color:</strong> {order.ProductColor}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      <strong>Status:</strong> <span style={{background:order.Status === "Pending" ?"yellow": order.Status === "Rejected" ? "red" : order.Status === "Accepted" ? "blue" : order.Status === "Cancelled" ? "orange": "green",fontWeight:'bold',padding:5,color: order.Status === "Pending" ? '#000' : '#fff'}}>{order.Status}</span>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Ordered on: {new Date(order.created_at).toLocaleDateString("en-IN")}
                    </Typography>
                  </Box>
                  <Box sx={{display:'flex',height:'40vh',width:'120px',alignItems:'center'}} component='div'>
                  {order.Status !== "Cancelled" && order.Status !== "Completed"  &&
                    <Button sx={{height:'10vh'}} color={"error"} variant={"contained"} onClick={()=>handleCancel(order._id)}>Cancel Order</Button>
                  }
                    </Box>
                </Box>
              ))
            ) : (
              <Typography variant="h5" color="textSecondary">
                No orders found
              </Typography>
            )}
          </Box>
        </Box>
          <ToastContainer/>
      </center>
  </>
);
};

export default Order;
