import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Muinavbar from "./Muinavbar";
import { useNavigate } from "react-router";
const mycomponent = {
  height: "auto",
  maxHeight: "300px",
  overflowY: "scroll",
};

const Cart = ({ setCartDataLength, cartDataLength }) => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);

  let ta = 0;
  let dis = 0;
  let gst = 0;
  let finalPrice = 0;
  Data?.map((item) => {
    ta = ta + item?.ProductPrice;
    dis = dis + item?.ProductDiscount;
    gst = gst + item?.ProductGST;
    return item;
  });
  
  let discount = 0;
  discount = ta - (ta * dis) / 100;
  finalPrice = discount + (discount * gst) / 100;
    

  const getData = useCallback(async () => {
    const cartData = JSON.parse(localStorage.getItem("cartdata"));
    console.log(cartData)
    setData(cartData);
    setCartDataLength(cartData?.length);
  }, [setCartDataLength]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCartremove = (id) => {
    const indexToRemove = Data?.findIndex((item) => item._id === id);
    if (indexToRemove !== -1) {
      Data?.splice(indexToRemove, 1);
    }
    localStorage.setItem("cartdata", JSON.stringify(Data));
    setCartDataLength(Data?.length === 0 ? 0 : Data.length);
  };
  return (
    <>
      <Muinavbar cartDataLength={cartDataLength} />
      <center>
        <Box
          sx={{
            width: "96%",
            height: 450,
            mt: 13,
            display: "flex",
            flexDirection: ["row","column"],
            justifyContent: "space-between",
            flexWrap:'wrap'
          }}
        >
          {/* price detail */}
          <Box
            sx={{ width: ["100%","30%"], height: "auto", backgroundColor: "white", pt: 1,borderRadius:4 }}
          >
            <Typography variant="h5" color="grey">
              Price Details
            </Typography>
            <Divider />
            <Box sx={{ width: "90%", height: 250, mt: 4 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  Price ({Data?.length} Items)
                </Typography>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  ₹
                  {ta > 9999
                    ? ta.toString().slice(0, 2) + "," + ta.toString().slice(2)
                    : ta > 999
                    ? ta.toString().slice(0, 1) + "," + ta.toString().slice(1)
                    : ta}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  GST ({Data?.length} Items)
                </Typography>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  {gst+'%'}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  Discount ({Data?.length} Items)
                </Typography>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  { dis + '%'}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  Delivery Charges
                </Typography>
                <Typography variant="body1" textAlign={"left"} mt={3}>
                  {"₹ 00"}
                </Typography>
              </Box>
              <br />
              <br />
              <Divider />
              <br />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" textAlign={"left"}>
                  Total Amount:
                </Typography>
                <Typography variant="h5" textAlign={"left"}>
                  ₹
                  {finalPrice !== 0 ? finalPrice.toFixed(0) > 9999
                    ? finalPrice.toFixed(0).toString().slice(0, 2) +
                      "," +
                      finalPrice.toFixed(0).toString().slice(2)
                    : finalPrice.toFixed(0) > 999
                    ? finalPrice.toFixed(0).toString().slice(0, 1) +
                      "," +
                      finalPrice.toFixed(0).toString().slice(1)
                    : finalPrice.toFixed(0) : 
                  discount.toFixed(0) > 9999
                    ? discount.toFixed(0).toString().slice(0, 2) +
                      "," +
                      discount.toFixed(0).toString().slice(2)
                    : discount.toFixed(0) > 999
                    ? discount.toFixed(0).toString().slice(0, 1) +
                      "," +
                      discount.toFixed(0).toString().slice(1)
                    : discount.toFixed(0)
                  }
                </Typography>
              </Box>
              <br />
              <Divider />
            </Box>
          </Box>

          {/* cart items */}
          <Box sx={{ width: ["100%","30%"], height: "auto", backgroundColor: "white" ,borderRadius:4 }}>
            <br />
            <Typography variant="h5">Shopping Items</Typography>
            <br />
            <Divider />
            <Box sx={{ width: ["100%","150%"], height: 300 }}>
              <Box sx={mycomponent}>
                {Data?.length > 0 ? (
                  Data?.map((item) => {
                    return (
                      <Box
                        key={item._id}
                        sx={{
                          height: 220,
                          width: "95%",
                          mt: 1,
                          mb: 1,
                          border: "1px solid grey",
                          borderRadius: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "90%",
                            height: 180,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            width={"20%"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            margin={2}
                          >
                            <img
                              src={item.ProductMainImgUrl}
                              width={100}
                              height={120}
                              alt={item.ProductName}
                            />
                          </Box>
                          <Box width={"80%"} height={"90%"} textAlign={"left"} ml={[2,0]}>
                            <Typography
                              variant="h6"
                              sx={{
                                width: "60%",
                                mt: 2,
                                height: 70,
                                overflow: "hidden",
                              }}
                            >
                              {item.ProductName}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ width: "60%", mt: 3 }}
                            >
                              ₹
                              {item.ProductPrice > 9999
                                ? item.ProductPrice.toString().slice(0, 2) +
                                  "," +
                                  item.ProductPrice.toString().slice(2)
                                : item.ProductPrice > 999
                                ? item.ProductPrice.toString().slice(0, 1) +
                                  "," +
                                  item.ProductPrice.toString().slice(1)
                                : item.ProductPrice}
                              &nbsp;
                              <span className="cut-price">
                                ₹
                                {item.ProductMRP > 9999
                                  ? item.ProductMRP.toString().slice(0, 2) +
                                    "," +
                                    item.ProductMRP.toString().slice(2)
                                  : item.ProductMRP > 999
                                  ? item.ProductMRP.toString().slice(0, 1) +
                                    "," +
                                    item.ProductMRP.toString().slice(1)
                                  : item.ProductMRP}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              width: "90%",
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => handleCartremove(item._id)}
                            >
                              <DeleteIcon sx={{ mr: 1 }} />
                              Remove
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <h1>Your cart is empty </h1>
                )}
              </Box>
            </Box>
            <Box
              position="relative"
              sx={{
                width: ["100%","150%"],
                height: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{ backgroundColor: "#f3dfa3", color: "black", mr: 5 }}
                onClick={() => navigate("/payment")}
              >
                Place Order
              </Button>
            </Box>
          </Box>
        </Box>
      </center>
    </>
  );
};
export default Cart;
