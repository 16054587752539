import React, { useEffect, useState } from "react";
import Muinavbar from "./Muinavbar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router";
import axios from "axios";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import CloseIcon from '@mui/icons-material/Close';
import {   Divider, TextField,Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
export default function Profile({ cartDataLength }) {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [RefData, setRefData] = useState([]);
  const [refDialog, setRefDialog] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);

   const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
  };
  const handleClose = () => {
    setRefDialog(false);
  };
  const gotoorders = () => {
    navigate("/orders");
  };
  const gotocart = () => {
    navigate("/cart");
  };

  const getUserData = async () => {
    let userid = localStorage.getItem("UserId");
    let res = await axios.get(
      `https://ecombackend.glitch.me/getuserdata/${userid}`
    );
    if (res.data) {
      setData(res.data.data);
    }

    let res1 = await axios.get(`https://ecombackend.glitch.me/getReference/${res.data.data[0].ReferenceID}`);
    if(res1.data){
      setRefData(res1.data.data);
    }
  };
  useEffect(() => {
    const checkLogin = localStorage.getItem("LoginUser");
    if (!checkLogin) {
      navigate('/')      
      toast.info("Please Login", { autoClose: 5000 });
    }
    getUserData();
  }, [navigate]);

  return (
    <>
      <Muinavbar cartDataLength={cartDataLength} />
      <center>
        <Box
          sx={{
            width: "95%",
            height: 700,
            mt: 10,
            pt: 4,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: 300, height: 600, bgcolor: "white", p: 2 }}>
            <Box
              sx={{
                width: "100%",
                height: 80,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "25%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AccountCircleIcon sx={{ width: 60, height: 60 }} />
              </Box>
              <Box
                sx={{
                  width: "75%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle2" textAlign={"left"}>
                  Hello, {Data[0]?.FirstName}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ width: "100%", textAlign: "left", mt: 2 }}>
              <Button
                size="large"
                sx={{
                  color: "black",
                  fontSize: 15,
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                  mb: 1,
                }}
              >
                Profile Information
              </Button>
              <Button
                size="large"
                sx={{
                  color: "black",
                  fontSize: 15,
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                  mb: 1,
                }}
                onClick={gotoorders}
              >
                My Orders
              </Button>
              <Button
                size="large"
                sx={{
                  color: "black",
                  fontSize: 15,
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                  mb: 1,
                }}
                onClick={gotocart}
              >
                My Cart
              </Button>
              <Button
                size="large"
                sx={{
                  color: "black",
                  fontSize: 15,
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: 1200, height: 600, bgcolor: "white", p: 4 }}>
            <Box component={'div'} sx={{display:'flex',width:'90%',justifyContent:'space-between'}}>
            <Typography
              variant="h6"
              sx={{ width: "90%", textAlign: "left", mb: 4 }}
            >
              Personal Information
            </Typography>
            <Button onClick={()=>setRefDialog(true)} sx={{width:'300px',height:'50px'}} variant="contained">
              Your Reference ID
            </Button>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1.5, width: "45%" },
                height: 500,
              }}
            >
              <TextField
                type="text"
                variant="outlined"
                value={Data[0]?.FirstName}
                placeholder="First Name"
              />
              <TextField
                type="text"
                variant="outlined"
                value={Data[0]?.LastName}
                placeholder="Last Name"
              />
              <TextField
                type="tel"
                variant="outlined"
                value={Data[0]?.Mobile}
                placeholder="Mobile Number"
              />
              <TextField
                type="email"
                variant="outlined"
                value={Data[0]?.Email}
                placeholder="Email"
              />
              <Box sx={{display:'flex',justifyContent:'start',m: 5,width:'100%'}}>
              <Button size="medium" variant="contained">
                Update Details
              </Button>
              </Box>
            <Box component={'div'} sx={{display:'flex',width:'90%',justifyContent:'space-between'}}>
            <Typography
              variant="h6"
              sx={{ width: "90%", textAlign: "left", mb: 4 }}
              >
              Reference Information
            </Typography>
            </Box>
      <List sx={{width:'90%'}}>
        {RefData.map((ref, index) => (
          <ListItem key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
            <ListItemText
              primary={`${ref.FirstName} ${ref.LastName}`}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textPrimary">
                    Email: {ref.Email}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="textPrimary">
                    Mobile: {ref.Mobile}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
          </Box>
          </Box>
        </Box>
        <Dialog open={refDialog} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Your Reference ID
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CopyToClipboard text={Data[0]?.ReferenceID} onCopy={onCopyText}>
          <Typography variant="h6" gutterBottom>
              {Data[0]?.ReferenceID}
          </Typography>
      </CopyToClipboard>
      {copyStatus && <p>Text copied to clipboard!</p>}
          <Typography variant="body2" gutterBottom>
            Share your reference ID on social media:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
            <FacebookShareButton url={`http://nutnutritions.com/?popupsignup=true&?ref=${Data[0]?.ReferenceID}`} quote={`My reference ID: ${Data[0]?.ReferenceID}`}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={`http://nutnutritions.com/?popupsignup=true&ref=${Data[0]?.ReferenceID}`} title={`My reference ID: ${Data[0]?.ReferenceID}`}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={`http://nutnutritions.com/?popupsignup=true&ref=${Data[0]?.ReferenceID}`} title={`My reference ID: ${Data[0]?.ReferenceID}`}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </center>
    </>
  );
}
