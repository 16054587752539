import React, { useEffect, useState } from "react";
import {
  Box,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  TextField,
  TablePagination,
} from "@mui/material";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//icons
import DeleteIcon from "@mui/icons-material/Delete";

// import axios from "axios";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AddTask, Check, Clear, Search } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Users() {
  const navigate = useNavigate();
  const checklogin = localStorage.getItem("login");
  if (!checklogin) {
    navigate("/");
  }
  const [Data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteitem, setdeleteitem] = React.useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let i = 0;
  // ---delete Code----
  const handleclose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    setOpen(true);
    setdeleteitem(id);
  };

  const confirmDelete = async (id) => {
    let res = await axios.delete(
      `https://ecombackend.glitch.me/Orders/delete/${id}`
    );
    if (res.data) {
      toast.success(res.data.message);
      getData();
    }
  };

  const getData = async () => {
    let res = await axios.get(
      "https://ecombackend.glitch.me/Orders/List"
    );
    if (res) {
      setData(res.data.data);
    }
  };
  const handlerequest = async (id, Statusmsg) => {
    await axios
      .post("https://ecombackend.glitch.me/OrderUpdateStatus", {
        id,
        Statusmsg,
      })

      .then((res) => {
        toast.success(res.data.message);
        getData();
      })
      .catch((err) => {
        console.log("error");
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const acceptedOrders = Data.filter((order) => order.Status === "Accepted");
  const acceptedOrdersLength = acceptedOrders.length;

  const RejectedOrders = Data.filter((order) => order.Status === "Rejected");
  const RejectedOrdersLength = RejectedOrders.length;

  const CancelledOrders = Data.filter((order) => order.Status === "Cancelled");
  const CancelledOrdersLength = CancelledOrders.length;

  const PendingOrders = Data.filter((order) => order.Status === "Pending");
  const PendingOrdersLength = PendingOrders.length;

  const CompletedOrders = Data.filter((order) => order.Status === "Completed");
  const CompletedOrdersLength = CompletedOrders.length;

  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "#F5F5F5",
          height: "auto",
          marginLeft: isSmallerScreen ? 0 : "300px",
        }}
      >
        <Toolbar />
        <Typography
          variant="h4"
          sx={{ fontSize: "20px", color: "#6945FF", textAlign: "center" }}
        >
          Order Program
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            {/* // first Row */}
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={18}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "30px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Order Management
                </TableCell>
              </TableRow>
            </TableHead>
            {/* //second row */}
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "0px",
                    letterSpacing: "0em",
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    // onChange={(e) => setsearchQuery(e.target.value)}
                    sx={{ m: 1, width: "25ch" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Seach Here..."
                  />
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: "1px #007bff solid",
                      borderRadius: 2,
                      width: "100px",
                      height: "80px",
                    }}
                  >
                    <p align="center" al="true" style={{ fontWeight: "bold" }}>
                      Total Order
                    </p>
                    <p align="center" al="true">
                      {Data?.length}
                    </p>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: "1px #6c757d solid",
                      borderRadius: 2,
                      width: "120px",
                      height: "80px",
                    }}
                  >
                    <p
                      align="center"
                      al="true"
                      style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    >
                      Pending Order
                    </p>
                    <p align="center" al="true">
                      {PendingOrdersLength}
                    </p>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: "1px darkgreen solid",
                      borderRadius: 2,
                      width: "120px",
                      height: "80px",
                    }}
                  >
                    <p
                      align="center"
                      al="true"
                      style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    >
                      Accepted Order
                    </p>
                    <p align="center" al="true">
                      {acceptedOrdersLength}
                    </p>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: "1px darkgreen solid",
                      borderRadius: 2,
                      width: "120px",
                      height: "80px",
                    }}
                  >
                    <p
                      align="center"
                      al="true"
                      style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    >
                      Completed Order
                    </p>
                    <p align="center" al="true">
                      {CompletedOrdersLength}
                    </p>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: "1px #dc3545 solid",
                      borderRadius: 2,
                      width: "120px",
                      height: "80px",
                    }}
                  >
                    <p
                      align="center"
                      al="true"
                      style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    >
                      Rejected Order
                    </p>
                    <p align="center" al="true">
                      {RejectedOrdersLength}
                    </p>
                  </Box>
                </TableCell>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      border: "1px #ffc107 solid",
                      borderRadius: 2,
                      width: "120px",
                      height: "80px",
                    }}
                  >
                    <p
                      align="center"
                      al="true"
                      style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    >
                      Cancelled Order
                    </p>
                    <p align="center" al="true">
                      {CancelledOrdersLength}
                    </p>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            {/* // Thrid row */}
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Order Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Order Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Order Time
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Product Price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Product Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Completed
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Accept
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Reject
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            {Data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .reverse()
              .map((user) => {
                i = i + 1;
                const dateObject = new Date(user.created_at);
                const hours = dateObject.getHours();
                const ampm = hours >= 12 ? "PM" : "AM";
                const minutes = dateObject.getMinutes();
                const formattedHour = hours % 12 || 12;
                const formattedTime = `${formattedHour}:${
                  minutes < 10 ? "0" : ""
                }${minutes} ${ampm}`;

                return (
                  <TableBody key={user._id}>
                    <TableRow>
                      <TableCell align="center">{i}</TableCell>
                      <TableCell align="center">{user._id}</TableCell>
                      <TableCell align="center">
                        {dateObject?.toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center" sx={{ padding: 0 }}>
                        {formattedTime}
                      </TableCell>
                      <TableCell align="center">
                        {user.ProductName.slice(0, 20)}
                      </TableCell>
                      <TableCell align="center">{user.ProductPrice}</TableCell>
                      <TableCell align="center">
                        {user.ProductQuantity}
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          style={
                            user.Status === "Pending"
                              ? {
                                  backgroundColor: "orange",
                                  color: "white",
                                  padding: "5px",
                                }
                              : user.Status === "Accepted"
                              ? {
                                  backgroundColor: "blue",
                                  color: "white",
                                  padding: "5px",
                                }
                              : user.Status === "Completed"
                              ? {
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "5px",
                                }
                              : {
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "5px",
                                }
                          }
                        >
                          {user.Status}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <AddTask
                          sx={{ cursor: "pointer" }}
                          onClick={() => handlerequest(user._id, "Completed")}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Check
                          sx={{ cursor: "pointer" }}
                          onClick={() => handlerequest(user._id, "Accepted")}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Clear
                          sx={{ cursor: "pointer" }}
                          onClick={() => handlerequest(user._id, "Rejected")}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleDelete(user._id)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
          </Table>
          <TablePagination
            component="div"
            count={Data?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Dialog
          open={open}
          onClose={handleclose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your will not be able to recover this imaginary file!
            </DialogContentText>

            <DialogActions>
              <Button onClick={() => setOpen(false)}>cancle</Button>
              <Button onClick={() => confirmDelete(deleteitem)}>
                Yes, delete it!
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Box>
      <ToastContainer />
    </>
  );
}
